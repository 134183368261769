<template>
  <div style="background-color: #f2f3f5">
    <van-tabs v-model="active" @change="activeChange" swipeable>
      <van-tab
        v-for="item in tabs"
        :key="item.id"
        :title="item.name"
        :name="item.id"
      >
        <div :style="{ height: screeHeight - 44 + 'px' }" style="width: 95%;margin: 0 auto;overflow: auto" @scroll="Scroll">
          <!-- <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onMyLoad"
          > -->
          <div
            v-for="(item,orderIndex) in isOrders"
            :key="item.id"
            @click="toOrderInfo(item)"
            style="
              background-color: white;
              margin: 2px;
              margin-top: 10px;
              box-shadow: 0 2px 0 #efefef;
              border-radius: 10px;
              overflow: hidden;"
          >
            <div style="padding: 10px; background-color: #ff3933; color: white">
              {{ item.orgName }}
              <div style="float: right">{{ item.statusText }}</div>
            </div>
            <div style="padding: 10px 10px 0 10px">
              <div v-for="(ite,index) in item.itemList" :key="index">
                <div style="padding-bottom: 8px; font-size: 15px; color: #808080">
                  {{ item.createDay }} {{ item.createWeek }}
                </div>
                <van-row>
                  <van-col span="6">
                    <img :src="ite.logoUrl" style="width: 100%" alt="" />
                  </van-col>
                  <van-col span="18" style="padding-left: 6px">
                    <div
                      style="
                        font-size: 17px;
                        margin-top: -4px;
                        padding-bottom: 5px;
                      "
                    >
                      {{ ite.productName }}
                    </div>
                    <div style="font-size: 15px; color: #808080">
                      <div style="line-height: 25px">
                        使用日期：{{ ite.playDate }}
                      </div>
                      <!-- <div>使用时段：{{ ite.sysd }}</div> -->
                    </div>
                  </van-col>
                </van-row>
                <van-row
                  type="flex"
                  justify="space-between"
                  style="border-bottom: 1px solid #d8d8d8"
                >
                  <van-col span="6"
                    ><span style="font-weight: bold; font-size: 15px"
                      >￥{{ ite.salePrice }} </span
                    ><span style="color: #969799; font-size: 13px"
                      >x{{ ite.itemTotalQuantity }}</span
                    ></van-col
                  >
                  <van-col span="10" style="text-align: center"
                    ><span style="font-size: 14px; color: #4a4a4a">合计:</span
                    ><span
                      style="color: #ff6034; font-weight: bold; font-size: 18px"
                      >￥{{ (ite.salePrice * ite.itemTotalQuantity) }}</span
                    ></van-col
                  >
                </van-row>
              </div>
              <van-row
                type="flex"
                justify="space-between"
                style="line-height: 55px"
              >
                <van-col span="12">
                  <div v-if="item.status === 1">
                    剩余付款时间:
                    <span style="color: #ff6034; font-weight: bold"
                      >{{ getTimeLong(item,orderIndex) }}{{ item.timeLong }}</span
                    >
                  </div>
                </van-col>
                <van-col span="12" style="text-align: right;">
                  <span class="cancebtn" v-if="(item.status === 1 && item.itemList[0].isAppointment)" @click.stop="opration(item,'cancel')">取消订单</span>
                  <span class="cancebtn" v-if="item.status === 6 " @click.stop="opration(item,'del')">删除订单</span>
                  <span class="subbtn" v-if="item.status === 1" @click.stop="opration(item,'pay')">立即支付</span>
                  <!-- <span class="subbtn" style="background-color: snow;color: #fd1a34;border: 1px solid;" v-if="item.status === 2 || item.status === 3" 
                  @click.stop="opration(item,'refundTicket')">申请退款</span> -->
                  <span class="cancebtn" v-if="item.status === 4 || item.status === 2 || item.status === 3 || item.status === 5">查看订单</span>
                </van-col>
              </van-row>
            </div>
          </div>
          <!-- </van-list> -->
          <div v-if="loading && !finished" class="load">
            加载中...
          </div>
          <div v-if="page == totalPage" class="load">
          已无更多~
          </div>
        </div>
        <div v-if="isOrders.length < 1" style="padding: 40%; color: #646566">
          暂无数据
        </div>
        <!-- <div @click="toIndex" class="toIndex"><van-icon name="wap-home" /></div> -->
      </van-tab>
    </van-tabs>
    <!-- 支付方式 -->
    <van-popup v-model="isPay" closeable round position="bottom" :style="{ height: '30%' }" class="pay-cont">
      <div class="line1">订单金额 <span class="info">￥{{comOrder.actualPayFee}}</span></div>
      <!-- <div @click="toPay('trade.h5Pay')" class="line2">
        <span class="info"><van-icon name="alipay" /></span>支付宝</div>
      <div @click="toPay('WXPay.h5Pay')" style="" class="line3">
        <span style="font-size: 22px;color: #07c160;padding-right: 5px;"><van-icon name="wechat" /></span>微信</div> -->
      <div @click="toPay('uac.order')"  class="line4">
        <span style="font-size: 22px;color: #ff6034;padding-right: 5px;"><van-icon name="card" /></span>银联云闪付</div>
    </van-popup>
    
  </div>
</template>
<script>
import { numberToDate } from '../../utils';
import { Toast } from 'vant';
let down_time=[];
export default {
  name: "info",
  data() {
    return {
      screenWidth: document.documentElement.clientWidth, // 屏幕宽
      screeHeight: document.documentElement.clientHeight, // 屏幕高
      active: 2,
      tabs: [
        {
          id: 0,
          name: "全部",
        },
        {
          id: 1,
          name: "待付款",
        },
        {
          id: 2,
          name: "已支付",
        },
        {
          id: 4,
          name: "退款/售后",
        },
      ],
      orders:[],
      isOrders: [],
      loading: false,
      finished: false,
      page:1,
      totalPage:0,
      isPay:false,//弹出支付
      showQrCode:false,//弹出健康码
      ankangs:[],
      comOrder:{}
    };
  },
  watch: {
    $route() {
      this.getActive();
    },
  },
  mounted() {
    this.getActive();
  },
  methods: {
    // toIndex(){
    //   this.$router.push({path: '/'});
    // },
    Scroll (e) {
        // console.log(this.$refs.evabox.scrollTop) 
        const ele = e.srcElement ? e.srcElement : e.target
         // 监听滚动到div底部
        if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100) { 
          //底部设置一个loading 开启加载动画
          if (this.finished === false) {//避免多次请求 加锁 该次请求完成 才能再次发起请求
          this.loading = true
            this.finished = true
            this.onMyLoad()
           	// .....发起请求 请求结束  记得关锁 关闭loading
          }
        }
    },
    opration(order,type){
      this.comOrder = order
      let url = this.apis.orderCancel;//取消订单
      if(type === 'pay'){//去支付
        this.isPay = true;
        return
      }
      if(type === 'del'){//del
        url = this.apis.orderDel
      }
      if(type === 'refundTicket'){//退票
        url = this.apis.refundTicket
      }
      this.$http({
            method: "POST",
            url: `${url}?orderNo=${this.comOrder.orderNo}`,
        }).then((res) => {
            if (res.code == 200) {
              Toast({
                message: '操作成功',
                icon: 'success',
              });
              this.page = 1
              this.getOrder()
            }else{
              Toast({
                message: res.message,
                icon: 'fail',
              });
            }
        })
    },
    toPay(type){
      this.$loading.show()
      this.$http({
        method: "post",
        url: '/unionpay/h5/payOrderByWap',//`${this.payUrl}`,
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        data: {
            orderNo: this.comOrder.orderNo,
            payType:type,
        },
        transformRequest: [
          function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'));
            return ret
          }
        ],
      }).then((res) => {
        console.log(res)
        if (res.code == 200) {
          console.log(res)
          this.$store.commit('savePayUrl',res.data)
          // res.data 为接口返回的html完整文件代码
          // 必须要存进localstorage，否则会报错，显示不完全
          window.localStorage.removeItem("callbackHTML");
          window.localStorage.setItem("callbackHTML", res);
          // 读取本地保存的html数据，使用新窗口打开
          var newWin = window.open("", "_self");
          newWin.document.write(localStorage.getItem("callbackHTML"));
          // 关闭输出流
          newWin.document.close();
          // this.$router.push({path:'/href'})
          // window.location.href = res.data
        }
      })
    },
    toOrderInfo(ite){
      if(ite.status == 2 || ite.status == 3 || ite.status == 5 || ite.status == 4){
        localStorage.setItem('orderInfo',JSON.stringify(ite))
        this.$router.push({ path: "/order/orderInfo", query: {orderNo:ite.orderNo} });
      }else{
        Toast({
          message: '订单未支付或已取消',
          icon: 'circle',
        });
      }
      
    },
    getActive() {
      let active = this.$route.query.active;
      if (active) {
        this.active = +active;
      }
      this.clearIntervals()
      this.getOrder();
    },
    activeChange(index) {
      this.active = index;
      this.getOrder();
    },
    onMyLoad() {
      // 加载状态结束
      if(this.page >= this.totalPage){
        this.finished = true;
        return
      }
      this.loading = false;
      this.page++
      this.getOrder(1)
    },
    getOrder(loadMore) {
      this.clearIntervals()
      if(!loadMore){
        this.$loading.show()
      }
      this.$http({
          method: "post",
          url: `${this.apis.orderList}`,
          data: {
              type: this.active,
              page:this.page,
          },
          transformRequest: [
          function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'));
            return ret
          }
        ],
      }).then((res) => {
        this.$loading.hide()
        if (res.code == 200) {
          this.totalPage = res.totalPage
          if(!loadMore){
            this.isOrders = res.data
            return
          }
          this.finished = false
          this.isOrders = this.isOrders.concat(res.data)
        }
      })
      // this.isOrders = this.active === 0 ? this.orders : this.orders.filter((ite) => {
      //     return ite.zt == this.active;
      // });
    },
    getTimeLong(item,index){//计算剩余时间
      let time = item.invalidTimestamp;
      down_time.push('time' + index.toString());
      let current = Date.parse(new Date());
      if(item.status === 1){//待支付订单，计算倒计时
        if (time - current <= 0) {
          item.timeLong = 0;
          item.status = 6;//订单已取消
        } else {
          down_time[index] = setInterval(() => {
            let num = time - Date.parse(new Date());
            let date = numberToDate(num)
            if (date.m >= 0 || date.m != '00') {
              item.timeLong = date.m + '分钟';
            }
            if (date.m == '00') {
              item.timeLong = date.s + '秒';
            }
            if (num <= 0) {
              clearInterval(down_time[index]);
              item.status = 6;//订单已取消
              item.timeLong = -1
            }
            this.$forceUpdate()
          }, 1000)
        }
      }
    },
    clearIntervals() {
      if (down_time&&down_time.length > 0) {
        down_time.forEach((item) => {
          clearInterval(item)
        })
      }
    },
  },
  destroyed(){
    this.clearIntervals()
  }
};
</script>
<style lang="less" scoped>
.active {
  width: 70px;
  background-color: #fd1a34;
  color: white;
  text-align: center;
  border-radius: 15px;
  padding: 3px;
}
.nactive {
  width: 70px;
  padding: 3px;
  text-align: center;
  border-radius: 15px;
}
.sum {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  background-color: #f2f3f5;
  height: 45px;
  line-height: 45px;
}
.sub {
  width: 85px;
  background-color: #fd1a34;
  color: white;
  text-align: center;
  border-radius: 15px;
  padding: 3px;
  line-height: 25px;
  margin-top: 8px;
}
.pay-cont{
  .line1{
    padding: 15px;
    .info{
      color: #ff6034;
      font-size: 22px;
      font-weight: bold;
    }
  }
  .line2{
    line-height: 35px;
    font-size: 20px;
    padding: 5px 10px;
    border-top: 3px solid #ebedf0;
    .info{
      font-size: 22px;
      color: blue;
      padding-right: 5px;
    }
  }
  .line3{
    line-height: 35px;
    font-size: 20px;
    padding: 5px 10px;
    border-top: 1px solid #ebedf0;
  }
  .line4{
    line-height: 35px;
    font-size: 20px;
    padding: 5px 10px;
    border-top: 1px solid #ebedf0;
    border-bottom: 1px solid rgb(235, 237, 240);
  }
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
.cancebtn {
  border: 1px solid;
  color: #a2a2a2;
  text-align: center;
  border-radius: 15px;
  padding: 7px;
}
.subbtn {
  background-color: #fd1a34;
  color: white;
  text-align: center;
  border-radius: 25px;
  padding: 7px;
  margin-left: 8px;
}
.toIndex{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fd586b;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 5px;
  bottom: 90px;
  color: #969799;
  font-size: 19px;
}
.load{
  padding: 15px;
  text-align: center; 
  color: #646566
}
</style>